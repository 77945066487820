var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "page-header-index-wide page-header-wrapper-grid-content-main",
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { md: 24, lg: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "top-container",
                  staticStyle: { width: "100%" },
                  attrs: { bordered: false },
                },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 16, lg: 16 } },
                        [
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "昵称",
                              },
                            },
                            [_vm._v(_vm._s(_vm.userInfo.nickName))]
                          ),
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "邮箱",
                              },
                            },
                            [_vm._v(_vm._s(_vm.userInfo.email))]
                          ),
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "所属部门",
                              },
                            },
                            _vm._l(_vm.userInfo.departs, function (one) {
                              return _c("a-tag", { key: one.id }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(one.departName) +
                                    "\n                "
                                ),
                              ])
                            }),
                            1
                          ),
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "职位",
                              },
                            },
                            _vm._l(_vm.userInfo.positions, function (one) {
                              return _c("a-tag", { key: one.id }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(one.name) +
                                    "\n                "
                                ),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { md: 24, lg: 24 } },
            [
              _c(
                "a-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDesktop(),
                      expression: "isDesktop()",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    bordered: false,
                    tabList: _vm.tabListNoTitle,
                    activeTabKey: _vm.noTitleKey,
                  },
                  on: {
                    tabChange: (key) => _vm.handleTabChange(key, "noTitleKey"),
                  },
                },
                [
                  _vm.noTitleKey === "rating"
                    ? _c("rating-page")
                    : _vm.noTitleKey === "settlement"
                    ? _c("settlement-page")
                    : _vm.noTitleKey === "user-file"
                    ? _c("user-file-page", {
                        ref: "userForm",
                        on: {
                          changeUserTab: _vm.changeUserTab,
                          upImg: _vm.upImg,
                          openCropper: _vm.openCropper,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          staticClass: "login-user",
          attrs: {
            keyboard: false,
            "mask-closable": false,
            closable: false,
            width: "700px",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _vm.tab == "2"
                    ? _c(
                        "a-button",
                        { key: "back", on: { click: _vm.closeUserModal } },
                        [_vm._v("\n          关闭\n        ")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: { click: _vm.handleUserInfo },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.tab == "1" ? "下一步" : "保存") +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.userModalVisible,
            callback: function ($$v) {
              _vm.userModalVisible = $$v
            },
            expression: "userModalVisible",
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("\n        填写个人信息：\n        "),
            _c("span", { staticStyle: { color: "red", "font-size": "12px" } }, [
              _vm._v(
                "最迟需在第一次登录系统后的次日24点前填写完毕，否则无法正常进入系统。"
              ),
            ]),
          ]),
          _c("login-user-modal", {
            ref: "userForm",
            attrs: { tab: _vm.tab, userInfo: _vm.userInfo },
            on: {
              changeTabs: _vm.changeTabs,
              changeTab: _vm.changeTab,
              openCropper: _vm.openCropper,
              closeUserModal: _vm.closeUserModal,
            },
          }),
        ],
        1
      ),
      _c("user-cropper-model", {
        ref: "userCropperModel",
        on: { upImg: _vm.upImg },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            keyboard: false,
            "mask-closable": false,
            closable: false,
            title: "请选择可工作日",
            width: "35%",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.saveWork },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.workVisible,
            callback: function ($$v) {
              _vm.workVisible = $$v
            },
            expression: "workVisible",
          },
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "workForm",
                attrs: {
                  model: _vm.formData.userFile,
                  rules: _vm.validatorRules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { prop: "workDays", label: "通常每周可工作日" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        mode: "multiple",
                        placeholder: "通常每周可工作日",
                      },
                      on: { change: _vm.workDaysChange },
                      model: {
                        value: _vm.formData.userFile.workDays,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.userFile, "workDays", $$v)
                        },
                        expression: "formData.userFile.workDays",
                      },
                    },
                    [
                      _c(
                        "a-select-option",
                        { attrs: { value: 1, disabled: _vm.selectDis } },
                        [_vm._v("周一")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 2, disabled: _vm.selectDis } },
                        [_vm._v("周二")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 3, disabled: _vm.selectDis } },
                        [_vm._v("周三")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 4, disabled: _vm.selectDis } },
                        [_vm._v("周四")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 5, disabled: _vm.selectDis } },
                        [_vm._v("周五")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 6, disabled: _vm.selectDis } },
                        [_vm._v("周六")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 7, disabled: _vm.selectDis } },
                        [_vm._v("周日")]
                      ),
                      _c("a-select-option", { attrs: { value: -1 } }, [
                        _vm._v("跳过中国法定节假日"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    prop: "workdayAvailableCount",
                    label: "工作日每天可安排数量",
                  },
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    on: { change: _vm.workCountChange },
                    model: {
                      value: _vm.formData.userFile.workdayAvailableCount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.userFile,
                          "workdayAvailableCount",
                          $$v
                        )
                      },
                      expression: "formData.userFile.workdayAvailableCount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            keyboard: false,
            "mask-closable": false,
            closable: false,
            title: "个人信息",
            width: "35%",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.saveSelf },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.selfInfoShow,
            callback: function ($$v) {
              _vm.selfInfoShow = $$v
            },
            expression: "selfInfoShow",
          },
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "selfForm",
                attrs: {
                  model: _vm.formData.userFile,
                  rules: _vm.validatorSelfRules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    height: "20px",
                    "padding-bottom": "42px",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        "font-size": "12px",
                        top: "0",
                        color: "#aca9a9",
                      },
                    },
                    [_vm._v("下列信息仅BOSS及HR有权查看，请如实填写。")]
                  ),
                ]
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "姓名", prop: "userName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formData.userFile.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.userFile, "userName", $$v)
                      },
                      expression: "formData.userFile.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "性别", prop: "sex" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.formData.userFile.sex,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.userFile, "sex", $$v)
                        },
                        expression: "formData.userFile.sex",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("男")]),
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("女")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "出生日期", prop: "bornDate" } },
                [
                  _c("a-date-picker", {
                    attrs: { format: "YYYY-MM-DD" },
                    model: {
                      value: _vm.formData.userFile.bornDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.userFile, "bornDate", $$v)
                      },
                      expression: "formData.userFile.bornDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "国籍", prop: "country" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "国籍" },
                      model: {
                        value: _vm.formData.userFile.country,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.userFile, "country", $$v)
                        },
                        expression: "formData.userFile.country",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "中国" } }, [
                        _vm._v("中国"),
                      ]),
                      _c("a-select-option", { attrs: { value: "美国" } }, [
                        _vm._v("美国"),
                      ]),
                      _c("a-select-option", { attrs: { value: "英国" } }, [
                        _vm._v("英国"),
                      ]),
                      _c("a-select-option", { attrs: { value: "日本" } }, [
                        _vm._v("日本"),
                      ]),
                      _c("a-select-option", { attrs: { value: "韩国" } }, [
                        _vm._v("韩国"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "电话号码", prop: "phone" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入电话号码" },
                    model: {
                      value: _vm.formData.userFile.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.userFile, "phone", $$v)
                      },
                      expression: "formData.userFile.phone",
                    },
                  }),
                ],
                1
              ),
              (_vm.country == "qt" || _vm.country == "gat") &&
              _vm.formData.userFile.contractType != "意向全职" &&
              _vm.formData.userFile.contractType != "兼职"
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-top": "10px",
                          "padding-bottom": "15px",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "inline-block",
                              width: "160px",
                              "font-weight": "bold",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("英文银行账户信息")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              color: "rgba(0,0,0,0.25)",
                            },
                          },
                          [_vm._v("以下内容除收款账号，其余内容请输入英文信息")]
                        ),
                      ]
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "户名", prop: "paymentName" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入户名" },
                          model: {
                            value: _vm.formData.userFile.paymentName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "paymentName",
                                $$v
                              )
                            },
                            expression: "formData.userFile.paymentName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "收款账号",
                          prop: "alipayOtherAccount",
                        },
                      },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入收款账号" },
                          model: {
                            value: _vm.formData.userFile.bankAccount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "bankAccount",
                                $$v
                              )
                            },
                            expression: "formData.userFile.bankAccount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "银行名称", prop: "bankName" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入银行名称" },
                          model: {
                            value: _vm.formData.userFile.bankName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.userFile, "bankName", $$v)
                            },
                            expression: "formData.userFile.bankName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "支行名称", prop: "branchBank" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入支行名称" },
                          model: {
                            value: _vm.formData.userFile.branchBank,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.userFile, "branchBank", $$v)
                            },
                            expression: "formData.userFile.branchBank",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "SWIFT CODE", prop: "paymentBank" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入SWIFT CODE" },
                          model: {
                            value: _vm.formData.userFile.paymentBank,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "paymentBank",
                                $$v
                              )
                            },
                            expression: "formData.userFile.paymentBank",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "银行地址", prop: "bankAddress" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入银行地址" },
                          model: {
                            value: _vm.formData.userFile.bankAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "bankAddress",
                                $$v
                              )
                            },
                            expression: "formData.userFile.bankAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "个人地址" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入个人地址" },
                          model: {
                            value: _vm.formData.userFile.personalAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "personalAddress",
                                $$v
                              )
                            },
                            expression: "formData.userFile.personalAddress",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "收款账号",
                              prop:
                                _vm.country != "qt" &&
                                _vm.country != "gat" &&
                                _vm.formData.userFile.contractType !=
                                  "意向全职" &&
                                _vm.formData.userFile.contractType != "兼职"
                                  ? "alipayAccount"
                                  : "alipayOtherAccount",
                            },
                          },
                          [
                            _vm.formData.userFile.contractType == "意向全职" ||
                            _vm.formData.userFile.contractType == "兼职"
                              ? _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择收款方式",
                                      "default-value":
                                        _vm.formData.userFile.paymentType,
                                    },
                                    on: { change: _vm.paymentTypeChange },
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "EN_ACCOUNT" } },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            width: "1vw",
                                            position: "relative",
                                            left: "-3px",
                                          },
                                          attrs: { type: "bank" },
                                        }),
                                        _vm._v("\n                  全英账户"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "ALIPAY" } },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            width: "1vw",
                                            position: "relative",
                                            left: "-3px",
                                          },
                                          attrs: { type: "alipay-circle" },
                                        }),
                                        _vm._v("\n                  支付宝"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "WECHAT" } },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            width: "1vw",
                                            position: "relative",
                                            left: "-3px",
                                          },
                                          attrs: { type: "wechat" },
                                        }),
                                        _vm._v("\n                  微信"),
                                      ],
                                      1
                                    ),
                                    _vm.formData.userFile &&
                                    _vm.formData.userFile.workCity &&
                                    typeof _vm.formData.userFile.workCity ===
                                      "object" &&
                                    _vm.formData.userFile.workCity.some(
                                      (item) => item == 83
                                    )
                                      ? _c(
                                          "a-select-option",
                                          { attrs: { value: "BANK" } },
                                          [
                                            _c("a-icon", {
                                              staticStyle: {
                                                width: "1vw",
                                                position: "relative",
                                                left: "-3px",
                                              },
                                              attrs: { type: "credit-card" },
                                            }),
                                            _vm._v(
                                              "\n                  银行账户"
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.country != "qt" &&
                            _vm.country != "gat" &&
                            _vm.formData.userFile.contractType != "意向全职" &&
                            _vm.formData.userFile.contractType != "兼职"
                              ? _c(
                                  "a-input",
                                  {
                                    attrs: {
                                      defaultValue:
                                        _vm.formData.userFile.alipayAccount,
                                      placeholder: "请输入招行卡号",
                                    },
                                    on: { blur: _vm.alipayAccountBlur },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "1vw",
                                        position: "relative",
                                        left: "-4px",
                                      },
                                      attrs: {
                                        slot: "prefix",
                                        src: require("./../../../assets/zh.png"),
                                        alt: "",
                                      },
                                      slot: "prefix",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.formData.userFile.paymentType == "ALIPAY"
                              ? _c("a-input", {
                                  attrs: { placeholder: "请输入支付宝账号" },
                                  model: {
                                    value:
                                      _vm.formData.userFile.alipayOtherAccount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "alipayOtherAccount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.userFile.alipayOtherAccount",
                                  },
                                })
                              : _vm._e(),
                            _vm.formData.userFile.paymentType == "WECHAT"
                              ? _c("a-input", {
                                  attrs: { placeholder: "请输入微信账号" },
                                  model: {
                                    value:
                                      _vm.formData.userFile.alipayOtherAccount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "alipayOtherAccount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.userFile.alipayOtherAccount",
                                  },
                                })
                              : _vm._e(),
                            _vm.formData.userFile.paymentType == "BANK" &&
                            _vm.formData.userFile.contractType != "全职" &&
                            _vm.formData.userFile.contractType != "实习"
                              ? [
                                  _vm.country != "qt" && _vm.country != "gat"
                                    ? _c(
                                        "a-select",
                                        {
                                          attrs: {
                                            "show-search": "",
                                            placeholder: "请选择开户行",
                                            "option-filter-prop": "label",
                                          },
                                          model: {
                                            value:
                                              _vm.formData.userFile.bankName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData.userFile,
                                                "bankName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.userFile.bankName",
                                          },
                                        },
                                        _vm._l(_vm.bankList, function (item) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: item.value,
                                              attrs: { label: item.text },
                                            },
                                            [_vm._v(_vm._s(item.text))]
                                          )
                                        }),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.country != "qt" && _vm.country != "gat"
                                    ? _c("a-input", {
                                        attrs: {
                                          placeholder: "请输入银行卡号",
                                        },
                                        on: { blur: _vm.submitBank },
                                        model: {
                                          value:
                                            _vm.formData.userFile.bankAccount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.userFile,
                                              "bankAccount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.userFile.bankAccount",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.country != "qt" && _vm.country != "gat"
                                    ? _c("a-input", {
                                        attrs: { placeholder: "请输入户名" },
                                        model: {
                                          value:
                                            _vm.formData.userFile.paymentName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.userFile,
                                              "paymentName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.userFile.paymentName",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm.formData.userFile.paymentType == "EN_ACCOUNT"
                          ? [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-top": "10px",
                                    "padding-bottom": "15px",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "160px",
                                        "font-weight": "bold",
                                        "font-size": "14px",
                                      },
                                    },
                                    [_vm._v("英文银行账户信息")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        color: "rgba(0,0,0,0.25)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "以下内容除收款账号，其余内容请输入英文信息"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "户名" } },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入户名" },
                                    model: {
                                      value: _vm.formData.userFile.paymentName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "paymentName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.userFile.paymentName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "收款账号",
                                    prop: "alipayOtherAccount",
                                  },
                                },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入收款账号" },
                                    model: {
                                      value: _vm.formData.userFile.bankAccount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "bankAccount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.userFile.bankAccount",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "银行名称" } },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入银行名称" },
                                    model: {
                                      value: _vm.formData.userFile.bankName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "bankName",
                                          $$v
                                        )
                                      },
                                      expression: "formData.userFile.bankName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "支行名称" } },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入支行名称" },
                                    model: {
                                      value: _vm.formData.userFile.branchBank,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "branchBank",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.userFile.branchBank",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "SWIFT CODE" } },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入SWIFT CODE" },
                                    model: {
                                      value: _vm.formData.userFile.paymentBank,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "paymentBank",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.userFile.paymentBank",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "银行地址" } },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入银行地址" },
                                    model: {
                                      value: _vm.formData.userFile.bankAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "bankAddress",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.userFile.bankAddress",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "个人地址" } },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入个人地址" },
                                    model: {
                                      value:
                                        _vm.formData.userFile.personalAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "personalAddress",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.userFile.personalAddress",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
              _vm.formData.userFile.paymentType == "BANK" &&
              _vm.formData.userFile.contractType != "全职" &&
              _vm.formData.userFile.contractType != "实习" &&
              _vm.country == "qt"
                ? [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "名前片假名" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入名前片假名" },
                          model: {
                            value: _vm.formData.userFile.paymentName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "paymentName",
                                $$v
                              )
                            },
                            expression: "formData.userFile.paymentName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "銀行名" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入銀行名" },
                          model: {
                            value: _vm.formData.userFile.bankName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.userFile, "bankName", $$v)
                            },
                            expression: "formData.userFile.bankName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "店番号" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入店番号" },
                          model: {
                            value: _vm.formData.userFile.bankAccount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "bankAccount",
                                $$v
                              )
                            },
                            expression: "formData.userFile.bankAccount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "支店名" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入支店名" },
                          model: {
                            value: _vm.formData.userFile.branchBank,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.userFile, "branchBank", $$v)
                            },
                            expression: "formData.userFile.branchBank",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "口座番号" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入口座番号" },
                          model: {
                            value: _vm.formData.userFile.bankAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "bankAddress",
                                $$v
                              )
                            },
                            expression: "formData.userFile.bankAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "預金種別" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入預金種別" },
                          model: {
                            value: _vm.formData.userFile.personalAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.userFile,
                                "personalAddress",
                                $$v
                              )
                            },
                            expression: "formData.userFile.personalAddress",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "red",
                    "font-size": "12px",
                    position: "relative",
                    top: "-3px",
                  },
                },
                [
                  _vm._v(
                    "*本人承诺以上所填信息真实有效，若有虚假，本人自愿承担带来的经济损失"
                  ),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }